import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 720.000000 727.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,727.000000) scale(0.100000,-0.100000)" >
<path d="M1797 7263 c992 -2 2614 -2 3605 0 992 1 181 2 -1802 2 -1983 0
-2794 -1 -1803 -2z"/>
<path d="M2001 4428 c-5 -13 -16 -40 -25 -62 -15 -37 -21 -64 -11 -55 8 9 55
139 50 139 -3 0 -9 -10 -14 -22z"/>
<path d="M1077 4322 c-15 -17 -17 -73 -17 -595 0 -573 0 -577 21 -598 20 -20
28 -21 272 -17 226 4 261 6 335 27 235 66 367 247 380 523 2 58 1 136 -3 173
l-7 67 -34 -33 -34 -33 0 -110 c0 -61 -7 -141 -15 -179 -28 -134 -111 -248
-218 -299 -94 -46 -146 -53 -387 -53 l-225 0 -3 533 -2 532 212 0 c157 0 234
-4 293 -16 77 -16 82 -15 125 3 l45 20 -60 22 c-126 47 -157 51 -416 51 -226
0 -247 -2 -262 -18z"/>
<path d="M2148 4246 c-37 -23 -67 -44 -68 -48 0 -4 30 11 68 34 80 50 76 47
71 51 -2 2 -34 -15 -71 -37z"/>
<path d="M1778 4190 c-34 -18 -78 -67 -78 -87 0 -4 5 -1 10 7 9 13 11 13 20 0
8 -11 9 -8 4 13 -6 26 1 33 29 28 5 -1 6 3 2 9 -10 16 64 43 103 36 44 -7 48
-11 35 -39 -16 -31 -23 -67 -13 -67 11 0 20 27 15 43 -3 6 5 18 17 26 20 12
24 11 45 -13 27 -31 40 -75 26 -85 -6 -3 -13 -23 -17 -43 -4 -20 -15 -47 -26
-60 l-19 -23 -1 26 c0 14 -11 35 -25 47 -14 12 -25 18 -25 12 0 -5 9 -14 20
-20 13 -7 20 -21 20 -40 0 -17 4 -30 9 -30 15 0 49 49 56 81 4 16 10 27 15 24
17 -10 19 41 2 74 -42 85 -147 123 -224 81z"/>
<path d="M1810 4169 c-21 -11 -28 -19 -17 -19 26 0 21 -46 -8 -75 -26 -27 -45
-32 -45 -13 0 6 -2 9 -5 6 -8 -8 15 -56 29 -61 6 -2 20 -1 31 4 17 6 17 8 3 8
-22 1 -19 26 5 61 9 13 17 36 17 49 0 19 3 22 12 13 14 -14 38 -16 38 -2 0 5
-6 7 -14 4 -18 -7 -32 16 -16 26 7 5 20 6 28 2 10 -3 13 -1 8 6 -10 16 -25 14
-66 -9z"/>
<path d="M1355 4140 c-12 -19 -5 -793 7 -807 6 -7 18 -13 28 -13 27 0 30 42
30 418 l0 342 44 0 c49 0 103 -16 129 -38 21 -19 23 -17 30 35 l6 41 -46 16
c-52 18 -218 22 -228 6z"/>
<path d="M1694 4037 c4 -90 57 -146 143 -154 38 -4 60 0 92 16 41 21 81 71 81
101 -1 8 -7 1 -14 -15 -39 -90 -165 -123 -240 -64 -35 28 -48 54 -57 119 l-8
55 3 -58z"/>
<path d="M2203 4051 c-34 -3 -65 -10 -69 -14 -11 -11 129 3 141 14 6 6 6 8 0
7 -6 0 -38 -4 -72 -7z"/>
<path d="M2442 4003 c-23 -50 -27 -71 -27 -148 0 -80 3 -96 29 -142 16 -29 32
-53 36 -53 4 0 18 8 31 18 l22 19 -26 53 c-37 73 -37 151 -1 221 24 48 24 52
8 70 -28 31 -44 22 -72 -38z"/>
<path d="M3113 4041 l-22 -18 25 -49 c22 -42 25 -60 22 -124 -2 -51 -10 -88
-24 -115 l-21 -40 28 -23 28 -23 33 68 c28 58 32 79 33 143 0 64 -4 84 -30
135 -16 32 -34 60 -40 62 -5 1 -20 -6 -32 -16z"/>
<path d="M1846 3987 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M2550 3948 c-26 -58 -25 -126 2 -183 22 -45 35 -54 55 -34 7 7 5 21
-8 47 -25 48 -24 118 2 161 22 37 23 36 -9 45 -19 7 -25 2 -42 -36z"/>
<path d="M3013 3979 c-8 -8 -6 -20 8 -43 26 -42 25 -121 -1 -163 l-19 -33 21
-11 c22 -12 29 -6 54 51 17 38 10 142 -12 179 -19 33 -33 38 -51 20z"/>
<path d="M1830 3940 c-6 -11 -16 -17 -22 -14 -7 4 -8 3 -4 -4 11 -18 22 -14
40 13 9 14 12 25 7 25 -6 0 -15 -9 -21 -20z"/>
<path d="M2640 3894 c-10 -28 -10 -46 -1 -75 7 -22 15 -39 19 -39 21 1 29 21
28 70 -2 85 -25 107 -46 44z"/>
<path d="M2762 3910 c-12 -12 -22 -25 -23 -30 -2 -15 -1 -49 1 -57 0 -5 1
-167 0 -360 l-1 -353 65 0 66 0 0 338 c0 185 3 360 6 388 6 44 3 55 -15 73
-27 27 -71 27 -99 1z"/>
<path d="M2939 3918 c-13 -9 -14 -16 -5 -40 8 -21 7 -33 -2 -49 -11 -18 -11
-23 5 -35 25 -19 28 -18 41 11 17 37 15 80 -5 103 -14 16 -22 18 -34 10z"/>
<path d="M3500 3455 l0 -345 60 0 60 0 0 345 0 345 -60 0 -60 0 0 -345z"/>
<path d="M3095 3582 c-22 -10 -53 -35 -68 -55 -48 -63 -59 -102 -55 -193 3
-72 7 -87 33 -125 60 -86 155 -124 258 -105 39 7 127 62 127 79 0 6 -12 23
-26 39 l-27 27 -24 -19 c-73 -57 -213 -15 -213 64 0 21 4 21 151 20 123 -1
152 1 156 13 9 24 -16 155 -37 188 -35 57 -76 78 -159 82 -59 3 -85 0 -116
-15z m164 -108 c12 -15 21 -37 21 -50 0 -23 -2 -24 -90 -24 -84 0 -90 1 -90
21 0 11 13 34 29 50 40 40 100 41 130 3z"/>
<path d="M3856 3590 c-72 -22 -125 -107 -133 -215 -7 -92 13 -157 65 -208 90
-88 250 -89 332 -2 l24 25 -29 30 -28 29 -30 -21 c-22 -15 -45 -21 -88 -22
-50 -1 -62 3 -83 24 -30 30 -45 80 -25 80 8 1 77 2 154 3 l140 2 3 48 c6 94
-48 191 -123 223 -37 15 -133 17 -179 4z m151 -112 c11 -13 23 -35 27 -50 l6
-28 -99 0 -100 0 16 37 c20 49 39 62 89 63 30 0 46 -6 61 -22z"/>
<path d="M4334 3582 c-79 -40 -114 -110 -114 -228 0 -70 4 -89 28 -135 37 -72
93 -111 167 -117 94 -8 179 38 210 111 26 62 22 67 -45 67 -52 0 -60 -3 -60
-18 0 -63 -125 -81 -155 -22 -36 70 -26 214 18 245 36 25 92 19 116 -11 12
-15 21 -33 21 -40 0 -10 16 -14 60 -14 69 0 73 7 41 75 -21 47 -54 79 -95 95
-40 15 -157 10 -192 -8z"/>
<path d="M4842 3589 c-50 -15 -108 -79 -126 -140 -42 -140 8 -282 114 -328 88
-38 190 -23 253 37 53 50 71 98 71 187 -1 99 -13 138 -62 191 -54 60 -154 80
-250 53z m139 -99 c32 -17 49 -64 49 -133 0 -107 -23 -146 -92 -154 -72 -8
-108 41 -108 147 0 67 18 122 47 139 23 13 79 14 104 1z"/>
<path d="M5247 3594 c-4 -4 -7 -115 -7 -246 l0 -238 65 0 65 0 0 179 c0 175 1
180 22 195 28 20 88 21 114 2 17 -13 19 -30 22 -195 l3 -181 60 0 59 0 0 170
c0 168 0 171 25 195 17 18 35 25 64 25 68 0 71 -8 71 -211 l0 -179 66 0 66 0
-4 198 c-4 222 -12 252 -73 278 -70 29 -178 10 -207 -35 -16 -26 -25 -26 -33
-1 -17 54 -159 67 -224 21 l-32 -23 -6 21 c-4 17 -13 21 -43 21 -21 0 -45 2
-52 5 -8 3 -17 2 -21 -1z"/>
<path d="M6122 3583 c-52 -25 -86 -76 -84 -126 1 -23 4 -49 7 -58 9 -28 77
-67 148 -85 110 -27 150 -79 87 -112 -48 -25 -140 4 -140 45 0 9 -17 13 -61
13 l-61 0 7 -27 c25 -102 158 -159 287 -123 52 15 105 67 113 113 18 96 -23
134 -197 182 -68 18 -88 43 -64 80 31 48 119 37 133 -16 5 -16 15 -19 70 -19
l65 0 -7 28 c-10 41 -58 96 -96 109 -51 18 -168 16 -207 -4z"/>
<path d="M0 20 c0 -20 7 -20 3600 -20 3593 0 3600 0 3600 20 0 20 -7 20 -3600
20 -3593 0 -3600 0 -3600 -20z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
